import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const InfoBulletStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000;

  &:nth-child(even) {
    flex-direction: row-reverse;
  }

  .img-wrapper {
    height: 500px;
    width: 50%;
    overflow: hidden;
    background: #000;

    .gatsby-image-wrapper {
      height: 100%;
      width: 100%;
      filter: brightness(50%);
      transition: filter 0.5s, transform 0.5s;
    }
  }

  .info {
    height: 500px;
    width: 50%;
    background: #000;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .info-wrapper {
      padding: 0 5em;
      display: flex;
      flex-direction: column;
      justify-content: center;

      h2, p, a, button{
        margin: 0;
      }

      h2 {
        font-family: "prompt",Arial,Helvetica,sans-serif;
      }

      p {
        font-family: "raleway",Arial,Helvetica,sans-serif;
      }

      a {
        font-size: 1em;
        max-width: 200px;
        margin-top: 1em;
        text-align: center;
        border-color: #fff;
        color: #fff;

        &:hover {
          color: #000;
        }
      }
    }
  }

  &:hover {
    .img-wrapper {
      .gatsby-image-wrapper {
        height: 100%;
        width: 100%;
        filter: brightness(80%);
        transform: scale(1.1);
        transition: filter 0.5s, transform 0.5s;
      }
    }
  }

  @media (max-width: 800px) {
    display: grid;

    .img-wrapper, .info {
      display: grid;
      grid-area: 1/1/auto/auto;
      width: 100%;
    }

    .img-wrapper {
      .gatsby-image-wrapper {
        filter: brightness(50%);
      }
    }

    .info {
      background: none;
      z-index: 2;
    }
  }
`

export default function InfoBullet(props) {
  const image = getImage(props.image)

  return(
    <InfoBulletStyles>
      <div className="img-wrapper">
          <GatsbyImage
            image={image}
            alt={props.title}
            className="img" />
        </div>
        <div className="info">
          <div className="info-wrapper">
            {props.children}
          </div>
        </div>
    </InfoBulletStyles>
  )
}