import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import ReactPlayer from "react-player/youtube"
import Layout from "../components/layout"
import CallToAction from "../components/cta"
import SEO from "../components/seo"
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ShopNowButton from "../components/shopNow"
import InfoBullet from "../components/infoBullet"
import Product from "../components/product"

const HeroStyles = styled.div`
  background: black;
  display: grid;
  overflow: hidden;

  .hero-video-wrapper {
    width: 105%;
    height: 110vh;
    margin: -5%;
    overflow: hidden;

    .hero-video {
      filter: brightness(50%);
      height: inherit !important;
      width: inherit !important;
    }
  }

  .gatsby-image-wrapper {
    filter: brightness(50%);
  }

  .hero-content {
    height: 100%;
    display: grid;
    grid-area: 1/1 / auto/auto;
    position: relative;
    place-items: baseline;
    align-content: center;
    margin: 0 4em;

    h1 {
      margin: 0;
      color: #fff;
    }

    p {
      color: #fff;
      font-weight: 100;
      margin: 0 0 1em;
      font-size: 1.2em;
    }
  }

  @media (max-width: 600px) {
    h1 {
      font-size: 1.5em;
    }

    .hero-video-wrapper {
      height: 40vh;
      margin: -10% -5% -5%;

      .hero-video {
        //height: 40vh!important;
        width: 137% !important;
      }
    }

    .hero-content {
      margin: 0 auto;
      width: 90%;

      a {
        font-size: 1em;
      }
    }
  }
`

const SaleSectionStyles = styled.div`
  height: 400px;
  max-width: 1000px;
  margin: 0 auto;
  padding: 1em;
  display: flex;
  align-items: center;

  .gatsby-image-wrapper {
    width: 30%;
    align-self: center;
    margin-right: 1.5em;
  }

  .sale-content {
    border-left: 1px solid lightgray;
    padding-left: 1.5em;
    width: 70%;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    padding: 2em 1em;

    .gatsby-image-wrapper {
      margin-right: 0;
      margin-bottom: 1em;
      width: 90%;
    }

    .sale-content {
      width: 90%;
      border-left: none;
      padding-top: 1em;
      padding-left: 0;
      border-top: 1px solid lightgray;
    }
  }
`

const MissionWrapperStyles = styled.div`
  margin-bottom: 2em;

  h2 {
    font-family: "prompt", Arial, Helvetica, sans-serif;
    text-align: center;
    padding: 1em 0;
  }
`

const MissionStyles = styled.div`
  height: 400px;
  max-width: 1000px;
  margin: 0 auto;
  color: #fff;
  display: grid;
  font-family: "raleway", Arial, Helvetica, sans-serif;
  transform: skew(-20deg);
  background: black;
  overflow: hidden;

  .gatsby-image-wrapper {
    filter: brightness(50%);
    transform: skew(20deg);
    width: 115%;
    left: -7.5%;
  }

  .mission-content {
    display: grid;
    grid-area: 1/1 / auto/auto;
    position: relative;
    place-items: baseline;
    align-content: center;
    padding: 3em;

    p {
      transform: skew(20deg);
    }

    b {
      font-family: "prompt", Arial, Helvetica, sans-serif;
      font-size: 1.2em;
    }
  }
`

const WheelTypesStyles = styled.div`
  margin: 5rem 0 0;
`

const ProductsStyles = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding-top: 1em;

  a {
    text-decoration: none;
  }
`

export default function IndexPage({ data, location }) {
  const homePage = data.file.childMdx.frontmatter
  const productEdges = data.allShopifyProduct.edges
  const siteTitle = "Home"
  const saleImage = getImage(homePage.saleSection.image)
  const missionImage = getImage(homePage.mission.image)
  const wheelTypes = []
  const today = new Date()
  const saleStart = new Date(homePage.saleSection.startDate)
  const saleEnd = new Date(homePage.saleSection.endDate)
  const displaySale = today >= saleStart && today <= saleEnd

  for (let i = 0; i < data.wheelTypes.nodes.length; i++) {
    const type = data.wheelTypes.nodes[i].childMdx.frontmatter
    const slug = data.wheelTypes.nodes[i].childMdx.slug
    wheelTypes.push(
      <InfoBullet image={type.hero.image} title={type.type} key={i}>
        <h2>{type.type}</h2>
        <p>{type.homeDescription}</p>
        <CallToAction text={`Learn More`} link={`wheels/${slug}`} />
      </InfoBullet>
    )
  }

  const products = []
  for (let i = 0; i < productEdges.length || i < 4; i++) {
    products.push(<Product product={productEdges[i].node} key={i} />)
  }

  const schema = {
    "@context": "https://schema.org",
    "@type": "BikeStore",
    name: "Encore Wheels",
    image:
      "https://encorewheels.com/static/46042198c1378cb74971e4799706aa40/40c1b/logo.webp",
    "@id": "",
    url: "https://encorewheels.com/",
    telephone: "1-248-714-6028",
    address: {
      "@type": "PostalAddress",
      streetAddress: "1200 Holden Ave,",
      addressLocality: "Milford Charter Twp",
      addressRegion: "MI",
      postalCode: "48381",
      addressCountry: "US",
    },
  }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={siteTitle}
        keywords={[
          `carbon wheels`,
          `carbon fiber wheels`,
          `carbon bicycle wheels`,
          `high performance bicycle wheels`,
          `bike wheels`,
          `bmx cruiser wheels`,
          `29er bmx`,
          `fixed gear wheels`,
          `fixed wheels`,
          `fixie wheels`,
          `track wheels`,
          `road bike wheels`,
          `carbon wheelset`,
          `usa made wheels`,
        ]}
        schemaMarkup={schema}
      />
      <HeroStyles Tag="section">
        <div className="hero-video-wrapper" style={{ gridArea: "1/1" }}>
          <ReactPlayer
            className="hero-video"
            url={
              "https://www.youtube-nocookie.com/embed/kzCxrxgmh34?autoplay=1&showinfo=0&mute=1&loop=1&iv_load_policy=3&modestbranding=1&playsinline=1&disablekb=1&rel=0&fs=0&wmode=opaque&origin=https://encorewheels.com&widgetid=1"
            }
            playing={true}
            loop={true}
            muted={true}
            config={{
              youtube: {
                playerVars: {
                  showinfo: 0,
                  disablekb: 1,
                  controls: 0,
                  iv_load_policy: 3,
                  modestbranding: 1,
                },
              },
            }}
          />
        </div>
        {/* <iframe 
          title="Encore Wheels Hero Background"
          style={{ gridArea: "1/1" }} 
          allowFullScreen
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          src="https://www.youtube.com/embed/kzCxrxgmh34?autoplay=1&mute=1&loop=1&iv_load_policy=3&modestbranding=1&playsinline=1&disablekb=1&rel=0&showinfo=0&fs=0&wmode=opaque&origin=https://encorewheels.com&widgetid=1">
        </iframe> */}
        <div className="hero-content">
          <h1>{homePage.hero.title}</h1>
          {homePage.hero.homeDescription && <p>{homePage.hero.description}</p>}
          <CallToAction text={homePage.hero.callToAction} link="/shop/" />
        </div>
      </HeroStyles>
      {displaySale && (
        <SaleSectionStyles>
          <GatsbyImage image={saleImage} alt={homePage.saleSection.title} />
          <div className="sale-content">
            <h3>{homePage.saleSection.title}</h3>
            <p>{homePage.saleSection.description}</p>
          </div>
        </SaleSectionStyles>
      )}
      <ProductsStyles>{products}</ProductsStyles>
      <ShopNowButton text="Shop Now" />
      <MissionWrapperStyles>
        <h2>{homePage.mission.title}</h2>
        <MissionStyles>
          <GatsbyImage
            style={{
              gridArea: "1/1",
            }}
            image={missionImage}
            alt={homePage.mission.title}
          />
          <div className="mission-content">
            <p>
              <b>{homePage.mission.headline}</b> {homePage.mission.statement}
            </p>
          </div>
        </MissionStyles>
      </MissionWrapperStyles>
      <WheelTypesStyles>{wheelTypes}</WheelTypesStyles>
    </Layout>
  )
}

export const query = graphql`
  query HomePageQuery {
    file(name: { eq: "index" }) {
      name
      childMdx {
        id
        frontmatter {
          templateKey
          hero {
            title
            description
            callToAction
            image {
              childImageSharp {
                gatsbyImageData(quality: 90, height: 600, layout: FULL_WIDTH)
              }
            }
          }
          saleSection {
            image {
              childImageSharp {
                gatsbyImageData(quality: 90, height: 300, layout: FULL_WIDTH)
              }
            }
            title
            description
            startDate
            endDate
          }
          mission {
            title
            headline
            statement
            image {
              childImageSharp {
                gatsbyImageData(quality: 90, height: 300, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
    wheelTypes: allFile(filter: { sourceInstanceName: { eq: "wheelTypes" } }) {
      nodes {
        name
        childMdx {
          slug
          frontmatter {
            type
            homeDescription
            hero {
              image {
                childImageSharp {
                  gatsbyImageData(quality: 90, height: 300, layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
    allShopifyProduct(filter: { productType: { eq: "Bicycle Wheels" } }) {
      edges {
        node {
          id
          handle
          shopifyId
          title
          priceRangeV2 {
            minVariantPrice {
              amount
            }
          }
          media {
            preview {
              image {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`
