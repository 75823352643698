import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const ShopNowWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 2em 0;
`

const ShopNowStyles = styled(Link)`
    margin-top: 0.5rem;
    border: none;
    border-radius: 6px;
    padding: 1rem 3em;
    background: rgba(0,0,0,0);
    border: 3px solid #000;
    color: #000;
    font-family: "raleway",Arial,Helvetica,sans-serif;
    font-weight: 200;
    font-size: 1.5rem;
    cursor: pointer;
    transition: background 0.2s;
    text-decoration: none;

    &:hover {
      background: #000;
      color: #fff;
      transition: background 0.2s;
    }
`

const ShopNowButton = ({ text }) => {
    return (
      <ShopNowWrapper>
        <ShopNowStyles to="/shop/">
            {text}
        </ShopNowStyles>
      </ShopNowWrapper>
    )
}

export default ShopNowButton