import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const CTAStyles = styled(Link)`
    margin-top: 0.5rem;
    border: none;
    border-radius: 6px;
    padding: 1rem 3em;
    background: rgba(0,0,0,0);
    border: 3px solid #fff;
    color: #fff;
    font-family: "raleway",Arial,Helvetica,sans-serif;
    font-weight: 200;
    font-size: 1.5rem;
    cursor: pointer;
    transition: background 0.2s;
    text-decoration: none;

    &:hover {
    background: #fff;
    color: #144D29;
    transition: background 0.2s;
    }
`

const CallToAction = ({ text, link }) => {
    return (
        <CTAStyles to={link || "/"}>
            {text}
        </CTAStyles>
    )
}

export default CallToAction