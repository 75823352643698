import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const ProductStyles = styled.article`
  .product {
    width: 250px;
    color: #000;
    background: #fff;
    padding: 2em;
    margin: 0.5em;
    transition: all 0.5s;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 6px;

    .product-img {
      max-height: 250px;
    }

    .gatsby-image-wrapper {
      margin-bottom: 1em;
    }

    .details {
      display: flex;
      justify-content: space-between;

      p {
        font-family: "prompt", Arial, Helvetica, sans-serif;
        font-size: 0.75em;

        &.title {
          width: 65%;
        }

        &.price {
          width: 27%;
          color: #818589;
        }
      }
    }
  }

  @media (max-width: 800px) {
    justify-content: center;

    a {
      padding: 1em 0;
    }
  }
`

export default function Product({ product }) {
  const productImage =
    product?.media?.length > 0
      ? getImage(product?.media[0].preview.image)
      : undefined
  const price = parseFloat(product.priceRangeV2.minVariantPrice.amount).toFixed(
    2
  )

  return (
    <ProductStyles>
      <Link to={`/product/${product.handle}`}>
        <div className="product">
          <GatsbyImage
            image={productImage}
            alt={product.title}
            className="product-img"
          />
          <div className="details">
            <p className="title">{product.title}</p>
            <p className="price">Starting At {`$${price}`}</p>
          </div>
        </div>
      </Link>
    </ProductStyles>
  )
}
